import commonEn from "./en/common.json";
import entitiesEn from "./en/entities.json";

const translations = {
  en: {
    common: commonEn,
    entities: entitiesEn,
  },
};

export default translations;
